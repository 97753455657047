import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";

// markup
const YourIPIs = () => {
  let api_url = `${process.env.GATSBY_WP_REST_API_URL}/my_plugin/v1/users_ip`

  const [userData, setUserData] = useState()
  useEffect(()=>{
    fetch(api_url)
    .then(response => response.json())
    .then(result => {
      setUserData(JSON.parse(result))
    })
  },[])
  return (
    <Layout pageTitle="Your IP is..." pageSlug="your-ip-is">
      <dl>
        <dt>IP: </dt>
        <dd>{userData?.ip}</dd>
        <dt>Hostname: </dt>
        <dd>{userData?.remote_host}</dd>        
      </dl>

    </Layout>
  );
};

export default YourIPIs;

